<template>
    <div>
        <p class="exie-text-subtitle-1 font-weight-bold mt-10 mb-5">{{$t('accountSettings.billing.plansTitle')}}</p> 
        <v-row>
            <v-col cols="12" md="4">
                <v-card height="100%" class="px-5 py-5" rounded="xl" variant="flat" color="grey-lighten-4" min-height="250px">
                    <p class="exie-text-subtitle-1 font-weight-bold">
                        <span>{{$t('accountSettings.billing.plans.trial.title')}}</span>
                    </p>
                    <p class="mt-3 exie-text-body-2">
                        <span>{{$t('accountSettings.billing.plans.trial.description')}}</span>
                    </p>
                    <p class="my-6">
                        <span class="exie-text-h3">$0</span>
                    </p>
                    <p class="mb-3 exie-text-body-2">{{$t('accountSettings.billing.plans.trial.includedTitle')}}</p>
                    <p class="text-pre-line exie-text-body-2">
                        {{$t('accountSettings.billing.plans.trial.includedDescription')}}
                    </p>
                    <p class="mt-5">
                        <v-btn v-if="currentAccount.pro_status == 0" disabled size="large" block >
                            {{$t('accountSettings.billing.btnYourCurrentPlanTitle')}}
                        </v-btn>
                    </p>
                </v-card>
            </v-col>
            <v-col cols="12" md="4" height="100%">
                <v-card height="100%" class="px-5 py-5" rounded="xl" variant="flat" color="grey-lighten-4" min-height="250px">
                    <p class="exie-text-subtitle-1 font-weight-bold">
                        <span>{{$t('accountSettings.billing.plans.light.title')}}</span>
                    </p>
                    <p class="mt-3 exie-text-body-2">
                        <span>{{$t('accountSettings.billing.plans.light.description')}}</span>
                    </p>
                    <p class="my-6">
                        <span class="exie-text-h3">$20</span><span class="exie-text-body-2">/{{$t('accountSettings.billing.plans.light.month')}}</span>
                    </p>
                    <p class="mb-3 exie-text-body-2">{{$t('accountSettings.billing.plans.light.includedTitle')}}</p>
                    <p class="text-pre-line exie-text-body-2">
                        {{$t('accountSettings.billing.plans.light.includedDescription')}}
                    </p>
                    <p class="mt-5">
                        <v-btn @click="handleChangeToLight" variant="text" v-if="currentAccount.pro_status == 1" size="large" block>
                            {{$t('accountSettings.billing.btnDowngrateToLightTitle')}}
                            <FromProToLightModal @plan-changed="planChanged = true" />
                        </v-btn>
                        <v-btn :loading="loading" @click="handleUpgrateToLight" v-else-if="[0, -1].includes(currentAccount.pro_status)" size="large" block color="primary">
                            {{$t('accountSettings.billing.btnUpgradeToLight')}}
                        </v-btn>
                        <v-btn v-else-if="currentAccount.pro_status == 2" disabled size="large" block >
                            {{$t('accountSettings.billing.btnYourCurrentPlanTitle')}}
                        </v-btn>
                    </p>
                </v-card>
            </v-col>
            <v-col cols="12" md="4" height="100%">
                <v-card height="100%" class="px-5 py-5" rounded="xl" variant="flat" color="grey-lighten-4" min-height="250px">
                    <p class="exie-text-subtitle-1 font-weight-bold">
                        <span>{{$t('accountSettings.billing.plans.pro.title')}}</span>
                    </p>
                    <p class="mt-3 exie-text-body-2">
                        <span>{{$t('accountSettings.billing.plans.pro.description')}}</span>
                    </p>
                    <p class="my-6">
                        <span class="exie-text-h3">$50</span><span class="exie-body-2">/{{$t('accountSettings.billing.plans.pro.month')}}</span>
                    </p>
                    <p class="mb-3 exie-text-body-2">{{$t('accountSettings.billing.plans.pro.includedTitle')}}</p>
                    <p class="text-pre-line exie-text-body-2">
                        {{$t('accountSettings.billing.plans.pro.includedDescription')}}
                    </p>
                    <p class="mt-5">
                        <v-btn v-if="currentAccount.pro_status == 1" disabled size="large" block>
                            {{$t('accountSettings.billing.btnYourCurrentPlanTitle')}}
                        </v-btn>
                        <v-btn :disabled="loading || subscription?.cancel_at_period_end" @click="handleUpgrateToPro" v-else-if="[0, -1].includes(currentAccount.pro_status)" size="large" block color="primary">
                            {{$t('accountSettings.billing.btnUpgradeToPRO')}}
                        </v-btn>
                        <v-btn :disabled="loading || subscription?.cancel_at_period_end" v-else-if="currentAccount.pro_status == 2" size="large" block color="primary">
                            {{$t('accountSettings.billing.btnUpgradeToPRO')}}
                            <FromLightToProModal @plan-changed="planChanged = true" />
                        </v-btn>
                    </p>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card height="100%" class="px-6 py-5" rounded="xl" variant="flat" color="deep-purple-lighten-5">
                    <p class="exie-text-subtitle-1 font-weight-bold">
                        <span>{{$t('accountSettings.billing.plans.custom.title')}}</span>
                    </p>
                    <p class="mt-2 exie-text-body-2">
                        <span>{{$t('accountSettings.billing.plans.custom.description')}}: support@exie.app</span>
                    </p>
                </v-card>
            </v-col>
        </v-row>
    </div>
    <v-snackbar location="top right" color="success" v-model="planChanged" :timeout="5000">
        {{$t('accountSettings.billing.notifyPlanChanged')}}
    </v-snackbar>
</template>

<script>
import { useAppStateStore } from '@/stores/AppStateStore'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import api from '@/utils/api'
import { useRoute } from 'vue-router'
import FromLightToProModal from './FromLightToProModal.vue'
import FromProToLightModal from './FromProToLightModal.vue'
import { useAccountSubscriptionStore } from '@/stores/AccountSubscriptionStore'
export default {
    components: {FromLightToProModal, FromProToLightModal},
    setup() {
        const appStateStore = useAppStateStore()
        const {currentAccount} = storeToRefs(appStateStore)

        const route = useRoute()
        const accountId = route.params.account_id

        const accountSubscriptionStore = useAccountSubscriptionStore()
        accountSubscriptionStore.init(accountId)
        const { subscription } = storeToRefs(accountSubscriptionStore)

        const loading = ref(false)
        const planChanged = ref(false)

        const handleUpgrateToLight = async () => {
            loading.value = true
            const response = await api.post(`/api/billing/${accountId}/subscription-checkout-session`, {
                plan: 'light'
            })
            loading.value = false
            if (response.error) {
                console.log('something went wrong')
                return
            }

            window.location.assign(response.url)
        }

        const handleUpgrateToPro = async () => {
            loading.value = true
            const response = await api.post(`/api/billing/${accountId}/subscription-checkout-session`, {
                plan: 'pro'
            })
            loading.value = false
            if (response.error) {
                console.log('something went wrong')
                return
            }

            window.location.assign(response.url)
        }

        return {currentAccount, handleUpgrateToLight, handleUpgrateToPro, loading, planChanged, subscription}
    }
}
</script>