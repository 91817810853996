import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import Accounts from '../views/Accounts.vue'
import AccountCreate from '../views/AccountCreate.vue'
import AccountCreateIgResult from '../views/AccountCreateIgResult.vue'
import AccountCreateError from '../views/AccountCreateError.vue'
import AccountCreateFullList from '@/views/AccountCreateFullList.vue'
import Train from '../views/Train.vue'
import TestBot from '../views/TestBot.vue'
import Document from '@/views/Document.vue'
import AccountSettings from '@/views/AccountSettings.vue'
import History from '@/views/History.vue'
import Invitation from '@/views/Invitation.vue'
import AccountRedirect from '@/components/common/AccountRedirect.vue'
import HelpCenter from '@/views/HelpCenter.vue'
import IntegrationConnectionError from '@/components/accountSettings/integrations/IntegrationConnectionError.vue'
import ShopifyMain from '@/views/ShopifyMain.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/accounts',
    name: 'accounts',
    component: Accounts,
    meta: {
      navbar: "my_accounts",
      requireAuth: true
    }
  },
  {
    path: '/account/create',
    name: 'account-create',
    component: AccountCreate,
    meta: {
      navbar: "my_accounts",
      requireAuth: true
    }
  },
  {
    path: '/account/create/:ig_id(\\d+)',
    name: 'account-create-ig-result',
    component: AccountCreateIgResult,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/account/create/error',
    name: 'account-create-error',
    component: AccountCreateError,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/account/:account_id(\\d+)/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      navbar: 'dashboard',
      requireAuth: true
    }
  },
  {
    path: '/account/:account_id(\\d+)/train',
    name: 'train',
    component: Train,
    meta: {
      navbar: 'sources',
      requireAuth: true
    }
  },
  {
    path: '/account/:account_id(\\d+)/document/:document_id(\\d+)',
    name: 'document',
    component: Document,
    meta: {
      navbar: 'sources',
      requireAuth: true
    }
  },
  {
    path: '/account/:account_id(\\d+)/settings',
    name: 'account_settings',
    component: AccountSettings,
    meta: {
      navbar: 'account_settings',
      requireAuth: true
    }
  },
  {
    path: '/account/settings/integration/connect-error',
    name: 'integration-connection-error',
    component: IntegrationConnectionError,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/account/:account_id(\\d+)/history',
    name: 'history',
    component: History,
    meta: {
      navbar: 'history',
      requireAuth: true
    }
  },
  {
    path: '/account/:account_id(\\d+)/playground',
    name: 'playground',
    component: TestBot,
    meta: {
      navbar: 'playground',
      requireAuth: true
    }
  },
  {
    path: '/invitation/:invitation_code([a-zA-Z0-9-]+)',
    name: 'invitation',
    component: Invitation,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/shopify',
    name: 'shopify',
    component: ShopifyMain,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/help',
    name: 'help',
    component: HelpCenter,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/account/dashboard',
    component: AccountRedirect,
    alias: [
      '/account/train',
      '/account/settings',
      '/account/history',
      '/account/playground'
    ],
    meta: {
      requireAuth: false
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
