<template>
  <InjectedScripts />
  <div class="h-100">
    <v-layout class="h-100">
      <div v-if="appStateStore.loaded" class="h-100 w-100 d-flex flex-column justify-space-between">
        <RouteWatcher />
        <AppUpdater v-if="hasUpdate" />
        <v-app-bar elevation="0">
          <v-app-bar-title>
              <v-img
                      max-height="30px"
                      max-width="100px"
                      aspect-ratio="16/9"
                      position="left"
                      class="cursor-pointer"
                      src="/img/sidebar_logo.svg"
                      @click="goToHome"
                    />
          </v-app-bar-title>
          <v-app-bar-nav-icon v-if="!mdAndUp" variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        </v-app-bar>
        <v-navigation-drawer v-model="drawer" mobile-breakpoint="md" class="pa-1 pl-3 border-e-0">
          <div class="d-flex flex-column h-100">
            <SidebarMainMenu class="mb-auto" />
            <SidebarBottomMenu />
          </div>
        </v-navigation-drawer>
        
        <v-main class="d-flex justify-center h-100" style="min-height: 300px;">
          <v-sheet class="w-100 h-100 d-flex flex-column" max-width="960px">
            <v-container v-if="currentOnboardingStatus === 1 && routeAccountId" class="mb-auto">
              <OnboardingMain  />
              
            </v-container>
            <v-container v-else class="mb-auto">
              <AccountNotifications />
              <router-view />
            </v-container>
            <Footer />
          </v-sheet>
        </v-main>
        
      </div>
      <Websocket />
    </v-layout>
  </div>
</template>

<script>
import { useRouter, useRoute } from 'vue-router'
import { useDisplay } from 'vuetify'
import SidebarMainMenu from './components/SidebarMainMenu.vue'
import SidebarBottomMenu from './components/SidebarBottomMenu.vue'
import AccountNotifications from './components/AccountNotifications.vue'
import { mdiMenu } from '@mdi/js'

import { useAppStateStore } from './stores/AppStateStore'
import api from './utils/api'
import AppUpdater from '@/components/AppUpdater.vue'
import OnboardingMain from '@/components/onboarding/OnboardingMain.vue'
import Footer from '@/components/Footer.vue'
import Websocket from '@/components/ws/Websocket.vue'
import InjectedScripts from '@/components/analytics/InjectedScripts.vue'

import { storeToRefs } from 'pinia'
import { watch, ref } from 'vue'
import { useMixPanelStore } from './stores/MixPanelStore'
import RouteWatcher from './components/common/RouteWatcher.vue'

export default {
  components: {RouteWatcher, OnboardingMain, AppUpdater, Footer, Websocket, SidebarMainMenu, SidebarBottomMenu, AccountNotifications, InjectedScripts},
  setup() {
    const router = useRouter()
    const route = useRoute()

    const {mdAndUp} = useDisplay()

    const drawer = ref(mdAndUp.value ? true: false)

    const goToHome = () => {
      router.push('/')
    }

    const hasUpdate = ref(false)

    const handleHasUpdateEvent = (event) => {
      hasUpdate.value = true
    }
    document.addEventListener('swUpdated', handleHasUpdateEvent, { once: true })

    const routeAccountId = ref(route.params.account_id)
    const mixPanelStore = useMixPanelStore()
    const appStateStore = useAppStateStore()
    const {currentOnboardingStatus, user, userId} = storeToRefs(appStateStore)

    watch(route.params.account_id, (newVal) => {
      routeAccountId.value = newVal
    })

    watch(route, (to, from) => {
      mixPanelStore.track(`Page View ${to.name}`, {
        path: to.fullPath,
        account_id: to.params.account_id,
        from: from.fullPath
      })
      routeAccountId.value = to.params.account_id
    })

    watch(user, (newVal, oldVal) => {
      if(newVal) {
        window.clarity("identify", `${newVal.id}`)
        mixPanelStore.setUser(newVal)
      } else {
        mixPanelStore.unsetUser(newVal)
      }
    })

    const initApp = async () => {
      await appStateStore.loadState()

      if (!appStateStore.user) {
        let queryParams = null
        console.log(route.fullPath.match(/returnPath/gi)?.length)
        if(route.fullPath.match(/invitation/gi) && !route.fullPath.match(/returnPath/gi)) {
          queryParams = {returnPath: route.fullPath}
        }
        router.push({
          name: 'login',
          query: queryParams
        })
        return
      }
      const accountId = parseInt(route?.params.account_id)
      if (accountId) {
        if (accountId !== appStateStore.data?.currentAccount?.id) {
          const responseData = await api.put('/api/account/current', {
            account_id: accountId
          })
          if (accountId === responseData.id) {
            appStateStore.updateCurrentAccount(responseData)
          } else if (appStateStore.data?.currentAccount?.id) {
            router.push({
              name: route.name,
              params: {account_id: appStateStore.data.currentAccount.id}
            })
          } else {
            router.push({
              name: 'accounts'
            })
          }
        }
      }
    }

    initApp()

    return { 
      appStateStore,
      currentOnboardingStatus,
      routeAccountId,
      hasUpdate,
      drawer,
      mdiMenu,
      mdAndUp,
      goToHome
    }
  }
}
</script>

<style>
#app {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100dvh;
}

#container {
  height: 100dvh;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.align-center {
  text-align: center;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.text-pre-line {
   white-space: pre-line
}

a.exie-link:link {
  color: #5647FF;
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
}

a.exie-link:visited {
  color: #5647FF;
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
}

a.exie-link:hover {
  color: #5647FF;
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
}

a.exie-link:active {
  color: #5647FF;
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
}

.v-field--focused {
  background-color: #E8EAF6;
}

.exie-text-h1 {
  letter-spacing: 0;
  font-size: 88px;
  line-height: 96px;
  font-weight: 500;
}
.exie-text-h2 {
  letter-spacing: 0;
  font-size: 57px;
  line-height: 60px;
  font-weight: 400;
}
.exie-text-h3 {
  letter-spacing: 0;
  font-size: 48px;
  line-height: 50px;
  font-weight: 400;
}
.exie-text-h4 {
  letter-spacing: 0;
  font-size: 34px;
  line-height: 40px;
  font-weight: 400;
}
.exie-text-h5 {
  letter-spacing: 0;
  font-size: 23px;
  line-height: 32px;
  font-weight: 400;
}
.exie-text-h6 {
  letter-spacing: 0;
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
}
.exie-text-subtitle-1 {
  letter-spacing: 0;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
}
.exie-text-subtitle-2 {
  letter-spacing: 0;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
}
.exie-text-body-1 {
  letter-spacing: 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}
.exie-text-body-2 {
  letter-spacing: 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}
.exie-text-button {
  letter-spacing: 0;
  font-size: 15px;
  line-height: 36px;
  font-weight: 500;
}
.exie-text-caption {
  letter-spacing: .4px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}
.exie-text-overline {
  letter-spacing: 2px;
  font-size: 12px;
  line-height: 32px;
  font-weight: 400;
}
.text-uppercase {
  letter-spacing: 2px;
}
</style>
