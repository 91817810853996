import { defineStore } from "pinia"
import api from '@/utils/api'

export const useAccountSubscriptionStore = defineStore('accountSubscriptionStore', {
    state: () => ({
        accountId: null,
        subscription: null
    }),
    actions: {
        async init(accountId) {
            if (!this.accountId || this.accountId != accountId) {
                this.reload(accountId)
            }
        },
        async reload(accountId) {
            this.accountId = accountId
            const response = await api.get(`/api/billing/${accountId}/subscription`)
            this.subscription = response.subscription?? null
        },
        update(accountId, subscription) {
            if(this.accountId && this.accountId != accountId) {
                return
            }
            this.accountId = accountId
            this.subscription = subscription
        },
        delete(accountId) {
            if(this.accountId && this.accountId != accountId) {
                return
            }
            this.subscription = null
        }
    }
})