<template>
    <div v-if="userId" class="my-5 w-100 text-medium-emphasis">
        <v-expansion-panels v-model="accountsExpanded" flat :ripple="false">
            <v-expansion-panel elevation="0">
              <v-expansion-panel-title v-if="currentAccount" class="px-2">
                  <v-badge dot :color="getProStatusLabelColor(currentAccount?.pro_status)">
                      <v-avatar color="grey-darken-3" size="30" :image="currentAccount.picture"></v-avatar>
                  </v-badge>
                  <span class="mx-3 exie-text-subtitle-1 font-weight-bold">{{ currentAccount.name }}</span>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                  <v-list class="my-0" elevation="0" rounded="0" v-if="accounts.length">
                      <v-list-item :ripple="false" class="px-0 mx-0" rounded="0" v-for="account in accounts" @click="chooseAccount(account.id)">
                          <div class="d-flex">
                              <v-badge dot :color="getProStatusLabelColor(account?.pro_status)">
                                  <v-avatar color="grey-darken-3" size="24" :image="account.picture"></v-avatar>
                              </v-badge>
                              <span class="mx-3 exie-text-subtitle-2">{{ account.name }}</span>
                          </div>
                      </v-list-item>
                  </v-list>
              </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-divider class="my-1" />
        <div class="mt-3 py-3" v-if="currentAccount && currentAccount.onboarding_status != 1">
            <v-list variant="text" nav :selected="currentTab">
                <v-list-item class="font-weight-medium" :ripple="false" color="primary" v-for="option in menuOptions" :key="option.id" :value="option.id" :to="{name: option.route_to, params: option.params}">
                        <v-icon v-if="option.icon" class="mr-2" :icon="option.icon"></v-icon> {{ option.label }}
                </v-list-item>
            </v-list>
        </div>
    </div>
    <div v-else></div>
</template>

<script>
import { ref } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import { getProStatusLabel, getProStatusLabelColor } from '@/utils/AccountUtils'
import { useAppStateStore } from '@/stores/AppStateStore'
import { storeToRefs } from 'pinia'
import { watch } from 'vue'
import { useI18n } from "vue-i18n"
import api from '@/utils/api'

import { mdiCogOutline, mdiViewDashboardOutline, mdiSquareEditOutline, mdiPlayOutline, mdiArchiveOutline } from '@mdi/js'
import { mdiMenuDown } from '@mdi/js'
export default {
    setup() {
        const router = useRouter()
        const route = useRoute()
        const appStateStore = useAppStateStore()
        const {accounts, currentAccount, userId} = storeToRefs(appStateStore)

        const accountsExpanded = ref(false)

        const mainMenuKey = ref(0)

        const i18n = useI18n()

        const getMenuOptions = () => {
            return [
                {id: "dashboard", label: i18n.t('navbar.dashboard'), route_to: 'dashboard', params: {account_id: appStateStore.currentAccount?.id}, icon: mdiViewDashboardOutline},
                {id: "sources", label: i18n.t('navbar.sources'), route_to: 'train', params: {account_id: appStateStore.currentAccount?.id}, icon: mdiSquareEditOutline},
                {id: "playground", label: i18n.t('navbar.playground'), route_to: 'playground', params: {account_id: appStateStore.currentAccount?.id}, icon: mdiPlayOutline},
                {id: "history", label: i18n.t('navbar.history'), route_to: 'history', params: {account_id: appStateStore.currentAccount?.id}, icon: mdiArchiveOutline},
                {id: "account_settings", label: i18n.t('navbar.settings'), route_to: 'account_settings', params: {account_id: appStateStore.currentAccount?.id}, icon: mdiCogOutline},            
            ]
        }

        const menuOptions = ref(getMenuOptions())
        watch(currentAccount, () => {
            menuOptions.value = getMenuOptions()
        })

        watch(i18n.locale, () => {
            menuOptions.value = getMenuOptions()
        })

        const currentTab = ref(null)
        currentTab.value = route.meta.navbar

        router.afterEach((to, from) => {
            const cur = menuOptions.value.find((option) => option.id === to?.meta?.navbar)
            currentTab.value = cur
        })

        const chooseAccount = async (accountId) => {
            router.push({
                name: 'dashboard',
                params: {
                    account_id: accountId
                }
            })
        }

        return { 
            userId,
            currentTab, 
            menuOptions, 
            getProStatusLabel, 
            getProStatusLabelColor, 
            chooseAccount, 
            accounts, 
            currentAccount, 
            mdiCogOutline,
            mdiMenuDown,
            mdiViewDashboardOutline,
            mdiSquareEditOutline,
            mdiPlayOutline,
            mdiArchiveOutline,
            accountsExpanded
        }
    }
}
</script>

<style scoped>
.opacity1 {
    opacity: 1;
}
</style>